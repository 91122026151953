import React from "react";
import { useLocation, Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import ButtonPrimary from "../styles/button-primary/button-primary.styles";
import { NavContainer, Logo, MenuItemsContainer } from "./navbar.styles";


const Navbar = ({ screenSize }) => {
  const location = useLocation();
  return (
    <NavContainer>
      <Logo href="/">
        <img src={logo} alt="logo" />
      </Logo>
      {/* only show payment button if the path is not home */}
      {location.pathname !== "/" && (
        <MenuItemsContainer>
          <form
            method="post"
            action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"
          >
            <input
              type="hidden"
              name="LinkId"
              value="cd775677-6de3-4cdc-a4df-98ac8c72c629"
            />
            <ButtonPrimary type="submit" width={"100px"}>
              Make a Payment
            </ButtonPrimary>
            { (screenSize.width >=700) && <Link to="/" style={{ marginLeft: '10px'}}>
                <ButtonPrimary>Logout</ButtonPrimary>
              </Link>}
          </form>
        </MenuItemsContainer>
      )}
    </NavContainer>
  );
};

export default Navbar;
