import React from 'react';
import { ClockLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Spinner = ({ customerStatement }) => {
    const history = useHistory();
    const SpinnerStyles = styled.div `
         display: block;
         margin: 0 auto;
         border-color: red;
    `

    return (
        <SpinnerStyles>
            <ClockLoader
            size={50}
            //size={"150px"} this also works
            color={"#C8CCCB"}
            />

            { 
                customerStatement !== null &&
                history.push('/')
            }
            
        </SpinnerStyles>
    );
}

export default Spinner;