import styled from 'styled-components';

const ButtonPrimary = styled.button`
  color: #FFF;
  min-width: 150px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.theme.color.highlight};
  padding: 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  
  &:hover {
    cursor:pointer
  }

  :focus {
    outline: none;
  }
`

export default ButtonPrimary;