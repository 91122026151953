import styled from 'styled-components';

export const NavContainer = styled.div`
width: 100%;
background-color: #FFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
height: 100px;
position: fixed;
top: 0;
display: flex;
align-items: center;
z-index: 99;

@media (max-width: 667px) {
    height: 75px;
}
`
export const Logo = styled.a`
  margin-left: 8%;

  img {
      height: 70px;
      width: auto;

      @media (max-width: 667px) {
          height: 50px;
      }
      
      @media (max-width: 400px) {
          height: 35px;
      }
  }
`

export const MenuItemsContainer = styled.div`
    position: absolute;
    top: 26px;
    right: 20px;

    @media (max-width: 500px) {
        top: 15px;
        right: 5px;
      }
`;
