import React, { useState, lazy, Suspense } from 'react';
import styled from 'styled-components';
import { Switch, Route, useHistory } from 'react-router-dom';

// import CustomerLogin from './components/customer-login/customer-login';
import Spinner from './components/spinner/spinner';
// import Payment from './components/payment/payment';
import Navbar from './components/navbar/navbar';
import './App.css';


const StatementView = lazy(() => import('./components/statement-view/statement-view'));
const CustomerLogin = lazy(() => import('./components/customer-login/customer-login'));

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const App = () => {
  const [statement, setStatement ] = useState('');
  const [accountNum, setAccountNum ] = useState('');

  const [screenSize, setScreenSize] = React.useState({width: window.innerWidth, height: window.innerHeight});
    
  React.useLayoutEffect(() => {
      const updateWindowDimension = () => {
          setScreenSize({ width: window.innerWidth, height: window.innerHeight });
      }
      window.addEventListener('resize', updateWindowDimension);

      return () => window.removeEventListener('resize', updateWindowDimension);
  }, [])



  const [ toast, setToast ] = useState({
      show: false,
      toastMessage: '',
      color: ''
  })

  const history = useHistory();

//toast message function
const showToast = (toastMessage, color, redirect=false) => {
setToast({ show: true, toastMessage: toastMessage, color: color })
setTimeout(() => setToast ({ show: false, toastMessage: '' },
    //if true passed to 'redirect' argument, redirect home
    () => redirect && history.push('/')
),
5000);
}

const handleChange = (event) => {
event.preventDefault();
return setAccountNum(event.target.value);
}

const fetchFunc = async () => {
  try {
    const response = await fetch(`${apiUrl}/mystatement/${accountNum}`);
    const respJson = await response.json();
    if(response.status === 400 ) {
        return showToast(respJson, '#c81f1d')
    }
    setStatement(respJson);
    setAccountNum('');
    return history.push(`/mystatement/${accountNum}`);
  } catch (error) {
    console.log(error.message)
  }
}

const handleSubmit = async (ev) => {
  ev.preventDefault();
  if(accountNum.length <= 0 || accountNum.length >= 7) {
      showToast('Account no should not be less or more than 6 digits', '#c81f1d');
      return setAccountNum('');
  } 
  fetchFunc();
}
    
  return (
    <Page>
      <Navbar screenSize={screenSize}/>
      <Switch>
        <Suspense fallback={<Spinner customerStatement={null}/>}>
        <Route exact path="/" render={(props) => <CustomerLogin toast={toast} handleSubmit={handleSubmit} handleChange={handleChange} {...props}/> } />
          <Route path="/mystatement/:customerId" render={(props) => <StatementView screenSize={screenSize} customerStatement={statement} {...props}/> }/>
        </Suspense>
        {/* <Route exact path="/payment" component={Payment}/> */}
      </Switch>
    </Page>
  );
}

export default App;
